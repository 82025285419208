import React, {FC, useEffect, useRef, useState} from "react";

import eatPng from "../../../img/categoriesIconEat.png"
import drinkPng from "../../../img/categoriesIconDrink.png"
import s from "./MainCatigories.module.css"
import {AXI} from "../../../libs/axi";
import {NavLink} from "react-router-dom";

interface ItemCatigories {
    id: number;
    img: string;
    description: string | null;
    name: string;
}

interface Item {
    active?: number;
}

const MainCatigories:FC <Item>  = ({active=1}) => {

    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        fetch(`${AXI}/api/categories/special?id=${active}`)
            .then(res => res.json())
            .then((data: ItemCatigories[]) => {

                    setArrCat(data);
                },

                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [active])

    return (
        <div className={s.wrapper}>
            {arrCat.length > 0 && arrCat.map(({id,name,img})=>
                <div className={s.item} key={`${id}${name}`}  >
                <NavLink className={s.link} to={`/specialcat/${id}`}>

                    <div
                        // onClick={() => handleMenuClick(el[0])}
                        className={s.menuButtonCategory}
                    >
                        <div>{name}</div>

                        {img  && <img src={img !== "nophoto.png" ? `https://edabirja.ru/img${img}` : active === 1 ? eatPng : drinkPng }/>}
                    </div>

                </NavLink>
                </div>
            )}
        </div>
    )
}

export default MainCatigories