import {SearchForm} from "../../components/SearchForm";
import s from "./MenuPage.module.css";
import React, {useEffect, useRef, useState} from "react";

import {AXI} from "../../libs/axi";
import ItemOrderMenu from "../../components/ItemOrderMenu";
import eatPng from "../../img/categoriesIconEat.png";
import drinkPng from "../../img/categoriesIconDrink.png";
import Discount from "../Main/Discount";
import RecomendationItem from "../../components/ModalFormItem/RecomendationItem";
import {useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";


interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    discountprocent: number | null,
    description: string | null;
    name: string;
}

interface ItemCat {
    id: number;
    img: string;
    description: string | null;
    name: string;
}

interface Item_m {
    id: number;
    name?: string;
    img?: string;
    m_items: ItemCatigories[]
}

interface Items {
    items: Item_m[]
    totalItems: number
    totalPages: number

}
interface ObjectModal {
    id: number;
    count:number;
}

export const MenuPage = () => {
    const [activeDrink, setActiveDrink] = useState(1)
    const [arrCat, setArrCat] = useState({} as Items)
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [objectModal, setObjectModal] = useState<ObjectModal[]>([])
    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            setObjectModal(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);
    const setData = useSetLocationData();

    const handleClickRes = (id:number) => {
        const isObject = objectModal.filter((el)=> el.id === id).length > 0

        if(isObject){
            setData("searchText",id+1)
            setObjectModal(prevState => {     const updatedState = prevState.map(item =>
                item.id === id ? {...item, count: 0} : item
            );
                return updatedState.filter(item => item.count > 0);
            })
        } else {
            setData("searchText",id)
            setObjectModal(prevState =>
                [...prevState,{ id:id, count: 1 }]
            )}
    };

    useEffect(() => {
        let storedData = Number(localStorage.getItem('activeDrink'));

        if (storedData) {
            setActiveDrink(storedData);
        } else {
            localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
    }, [activeDrink]);


    useEffect(() => {
        setIsLoading(true);
        fetch(`${AXI}/api/items/menuitems?page=1&count=1000`)
            .then(res => res.json())
            .then((data) => {
                    setArrCat(data as Items);
                setIsLoading(false);
                },
            ).catch(error => {
            console.error('Error fetching data:', error);
            setIsLoading(false); // Устанавливаем isLoading в false в случае ошибки
        });

    }, [activeDrink])

    const [categories,setCategories] = useState<string | null>("")



    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const catValue = urlParams.get('categories');
        setCategories(catValue)
        if(catValue) {

            const targetElement = document.getElementById(catValue);
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop + 90,
                    behavior: 'smooth',
                });
            }
        }

    }, [arrCat,categories]);


    const handleMenuClick = (targetId: any) => {

        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop + 90,
                behavior: 'smooth',
            });
        }
    };


    useEffect(() => {

        const objectElements = Array.from(document.getElementsByClassName(s.titleGroup)) as HTMLElement[];
        const positions = objectElements.map((el) => el.offsetTop);
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const menu = menuRef.current;

            if (menu) {
                const currentIndex = positions && positions.findIndex((pos) => pos > scrollPosition);

                if (currentIndex !== -1) {

                    menu.scrollLeft = (currentIndex * (68 + 16)) - (68 + 16)

                }
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [arrCat]);

    const menuRef = useRef<HTMLDivElement>(null);
    const [objectPositions, setObjectPositions] = useState<number[]>([]);
    const [shouldScroll, setShouldScroll] = useState<boolean>(true);


    useEffect(() => {
        const menu = menuRef.current;
        const objectElements = Array.from(document.getElementsByClassName(s.titleGroup)) as HTMLElement[];

        const getObjectPositions = () => {
            const positions = objectElements.map((el) => el.offsetTop);
            setObjectPositions(positions);
        };

        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            if (menu && shouldScroll) {
                const currentIndex = objectPositions.findIndex((pos) => pos > scrollPosition);

                if (currentIndex !== -1) {
                    setShouldScroll(false);
                    menu.scrollTo({
                        left: currentIndex * window.innerWidth,
                        behavior: 'smooth',
                    });
                }
            }
        };


        getObjectPositions();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <Layout>
        <div >
            <SearchForm active={activeDrink} handleClickRes={handleClickRes} objectModal={objectModal} />
            <div className={s.refMenu}>
                <div className={s.wrapperCat}>
                    <div ref={menuRef} className={s.list}>
                        {arrCat?.items?.map(({id, name, img}) =>
                            <div className={s.itemCat} key={`${id}${name}`}>
                                <button
                                    onClick={() => handleMenuClick(id)}
                                    className={s.menuButtonCat}
                                >
                                    {img && <img
                                        src={   !img.includes('nophoto.png')
                                            ? `${img}`
                                            : `https://edabirja.ru/img/no-photos.png`}/>}
                                </button>
                                <div>{name}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={s.wrapper}>
            <div className={s.title}>Меню</div>
            {arrCat?.items?.map((el) =>
                <div className={s.wrapperItem} key={`${el.id}21`}>
                    <div id={String(el.id)} className={s.titleGroup}>{el["name"]} </div>
                    <div className={s.wrapperGroup}>
                        {el["m_items"].length > 0 && el["m_items"].map(({id, name, img, price, discountprocent, discount}, index) =>
                            <ItemOrderMenu
                                key={`${id}${name}${index}3`}
                                id={id}
                                name={name}
                                img={img}
                                price={price}
                                discount={discount}
                                discountprocent={discountprocent}
                                handleClickRes={handleClickRes}
                                objectModal={objectModal}
                            />
                        )}
                    </div>
                </div>)}
            </div>
        </div>
        </Layout>
    )
}