import React, {FC, useEffect, useRef, useState} from "react";

import eatPng from "../../../img/categoriesIconEat.png"
import drinkPng from "../../../img/categoriesIconDrink.png"
import s from "./SimilarProduct.module.css"
import ItemOrder from "../../../components/ItemOrder";
import {AXI} from "../../../libs/axi";

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    description: string | null;
    discountprocent: number | null,
    name: string;
}
interface ObjectModal {
    id: number;
    count:number;
}

interface Item {
    id?: number | null;
    handleClickRes?: (id:number)=>void;
    objectModal?: ObjectModal[];
}

const SimilarProduct:FC <Item>  = ({id:idItem,objectModal,handleClickRes}) => {

    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])
    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [idIt,setIdIt]= useState(0)

    const containerRef = useRef<HTMLDivElement>(null);



    useEffect(() => {
      if(idItem)  {

            if (fetching ) {
                setIdIt(idItem)
                fetch(`${AXI}/api/items/similar?id=${idItem}&page=${page}&count=5`)
                    .then(res => res.json())
                    .then((data) => {

                            setArrCat([...arrCat, ...data["items"]]);
                            setPage(prevState => prevState + 1)
                        },
                    ).finally(() => setFetching(false))
            }
            if ( idIt !== idItem ) {
                setIdIt(idItem)
                fetch(`${AXI}/api/items/similar?id=${idItem}&page=1&count=5`)
                    .then(res => res.json())
                    .then((data) => {

                            setArrCat([...data["items"]]);
                            setPage(1)
                        },
                    ).finally(() => setFetching(false))
            }
        }

    }, [idItem,fetching])


    const handleScroll = () => {
        const container = containerRef.current;

        if (container) {
            const { scrollHeight, scrollTop, clientHeight } = container;
            if (scrollHeight - (scrollTop + clientHeight) < 100) {
                setFetching(true);
            }
        }
    };

    useEffect(() => {
        if(arrCat.length > 0) {
            const container = containerRef.current;

            if (container) {
                container.addEventListener('scroll', handleScroll);
            }
            return () => {
                if (container) {
                    container.removeEventListener('scroll', handleScroll);
                }
            };
        }
    }, [arrCat]);



    return (
        arrCat.length > 0 ?
        <div
            style={{
                height: arrCat.length > 4 ?'800px' : "500px", // Установите фиксированную или максимальную высоту, чтобы создать прокрутку
                overflow: 'auto', // Установите overflow: auto, чтобы добавить полосы прокрутки при необходимости
            }}
            className={s.wrapper} ref={containerRef}>
            <div className={s.title}>Похожие блюда</div>
            {arrCat.length > 0 && arrCat.map(({id,name,img,price,discount,discountprocent},index)=>
                <ItemOrder
                    key={`${id}${name}${index}2`}
                    id={id}
                    name={name}
                    img={img}
                    price={price}
                    discountprocent={discountprocent}
                    handleClickRes={handleClickRes}
                    objectModal={objectModal}
                    discount={discount}
                />
            )}
        </div> : <div></div>
    )
}



export default SimilarProduct;
