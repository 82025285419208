import React, {FC, useEffect, useRef, useState} from "react";

import ItemOrder from "../../components/ItemOrder";
import {AXI} from "../../libs/axi";
import {useParams} from 'react-router-dom';
import s from "./SpecialCat.module.css"
import {SearchForm} from "../../components/SearchForm";
import RecomendationItem from "../../components/ModalFormItem/RecomendationItem";
import {useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    discountprocent: number | null,
    description: string | null;
    name: string;
}


interface ObjectModal {
    id: number;
    count:number;
}
const ActionCat = () => {
    const {id: nameId} = useParams();
    const [activeDrink, setActiveDrink] = useState(1)
    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])
    const [text, setText] = useState<string>("")
    const [totalFinal, setTotalFinal] = useState<number>(0)
    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [objectModal, setObjectModal] = useState<ObjectModal[]>([])

    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        let storedData = Number(localStorage.getItem('activeDrink'));
        if (storedData) {
            setActiveDrink(storedData);
        } else {
            localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
    }, [activeDrink]);
    useEffect(() => {
        if (fetching) {
            fetch(`${AXI}/api/items/actiontitems?id=${nameId}&page=${page}&count=10`)
                .then(res => res.json())
                .then((data) => {

                        setArrCat([...arrCat, ...data["items"]]);
                        setTotalFinal(data.totalItems)
                    setText(data["category"].name)
                        setPage(prevState => prevState + 1)
                    },
                ).finally(() => setFetching(false))
        }

    }, [nameId, fetching])

    useEffect(() => {

        if(arrCat.length < totalFinal) {
            fetch(`${AXI}/api/items/actiontitems?id=${nameId}&page=1&count=10`)
                .then(res => res.json())
                .then((data) => {

                        setText(data["category"].name)
                        setArrCat([...data["items"]]);

                        setPage(prevState => prevState + 1)
                    },
                ).finally(() => setFetching(false))
        }

    }, [nameId])

    const handleScroll = (e: any) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
            setFetching(true)
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            setObjectModal(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);

    const setData = useSetLocationData();

    const handleClickRes = (id:number) => {
        const isObject = objectModal.filter((el)=> el.id === id).length > 0

        if(isObject){
            setData("searchText",id+1)
            setObjectModal(prevState => {     const updatedState = prevState.map(item =>
                item.id === id ? {...item, count: 0} : item
            );
                return updatedState.filter(item => item.count > 0);
            })
        } else {
            setData("searchText",id)
            setObjectModal(prevState =>
                [...prevState,{ id:id, count: 1 }]
            )}
    };

    return (<Layout>
        <div className={s.wrapper} ref={containerRef}>
            <SearchForm active={activeDrink} handleClickRes={handleClickRes} objectModal={objectModal}  />

            <div className={s.title}>
                {text}
            </div>
            {arrCat.length > 0 && arrCat.map(({id, name, img, price, discount,discountprocent}, index) =>
                <ItemOrder
                    key={`${id}${name}${index}2`}
                    id={id}
                    name={name}
                    img={img}
                    price={price}
                    discount={discount}
                    discountprocent={discountprocent}
                    handleClickRes={handleClickRes}
                    objectModal={objectModal}
                />
            )}
        </div>
        </Layout>
    )
}


export default ActionCat;
