import React, {FC, useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import backImg from "../../../img/backHit.jpg";
import ItemOrder from "../../../components/ItemOrder";
import s from "./SliderHit.module.css"
import {AXI} from "../../../libs/axi";

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    discountprocent: number | null,
    description: string | null;
    name: string;
}


interface ObjectModal {
    id: number;
    count:number;
}

interface Item {
    active?: number;
    handleClickRes?: (id:number)=>void;
    objectModal?: ObjectModal[];
}

const SliderHit:FC <Item>  = ({active=1,handleClickRes,objectModal}) => {

    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        fetch(`${AXI}/api/items/top?maincategory=${active}`)
            .then(res => res.json())
            .then((data: ItemCatigories[]) => {

                    setArrCat(data);
                },

                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [active])

    const settings = {
        className:s.divItem,
        dots: false,
        infinite: true,
        arrows:false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 7000,
        adaptiveHeight: true,
    };

    return (
        arrCat.length > 0 ?
        <div className={s.wrapper}>
            <img className={s.backHit} src={backImg} alt={"backHit"} />
            <div className={s.title} >
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8906 7.5811C15.8075 7.4166 15.6802 7.27826 15.523 7.18143C15.3658 7.08459 15.1847 7.03304 14.9999 7.03248H9.99582V1.04747C10.0066 0.8287 9.94475 0.612489 9.81995 0.43221C9.69514 0.25193 9.51427 0.117604 9.30525 0.0499668C9.10431 -0.015925 8.88759 -0.0166655 8.68621 0.0478516C8.48482 0.112369 8.30912 0.238825 8.18434 0.409067L0.1778 11.3816C0.0774834 11.5261 0.0172472 11.6944 0.00318763 11.8696C-0.010872 12.0448 0.0217441 12.2205 0.0977344 12.3791C0.167713 12.5604 0.289279 12.7174 0.44751 12.8308C0.60574 12.9442 0.79375 13.0091 0.988461 13.0175H5.99255V19.0025C5.9927 19.2128 6.05957 19.4178 6.1836 19.588C6.30762 19.7582 6.48246 19.8849 6.68311 19.9501C6.78366 19.9812 6.88811 19.998 6.99336 20C7.15128 20.0004 7.30705 19.9636 7.44794 19.8925C7.58884 19.8214 7.71086 19.7181 7.80403 19.591L15.8106 8.61851C15.9184 8.46967 15.9829 8.29407 15.9971 8.11103C16.0112 7.92799 15.9744 7.74462 15.8906 7.5811ZM7.99418 15.9302V12.02C7.99418 11.7554 7.88874 11.5017 7.70105 11.3146C7.51336 11.1276 7.2588 11.0225 6.99336 11.0225H2.9901L7.99418 4.11977V8.02998C7.99418 8.29453 8.09962 8.54825 8.28731 8.73532C8.475 8.92239 8.72956 9.02748 8.995 9.02748H12.9983L7.99418 15.9302Z" fill="white"/>
                </svg>
                ХИТЫ
            </div>
            <div className={s.wrapperSlider}>
                <Slider {...settings}>
                {arrCat.length > 0 && arrCat.map(({id,name,img,price,discount,discountprocent},index)=>
                    <ItemOrder
                        key={`${id}${name}${index}3`}
                        id={id}
                        name={name}
                        img={img}
                        price={price}
                        discount={discount}
                        discountprocent={discountprocent}
                        handleClickRes={handleClickRes}
                        objectModal={objectModal}
                        isSlider={true}
                    />
                )}
                </Slider>
            </div>
        </div>
: <div />

    );

}

export default SliderHit