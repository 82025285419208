import qs from "qs";
import { useNavigate, useLocation, NavigateFunction  } from "react-router-dom";

export const useSetLocationData = () => {
  const history: NavigateFunction = useNavigate();
  let location = useLocation();
  const parameters = qs.parse(location.search.slice(1));

  return (
    name:any,
    val:any,
    name2 = "",
    val2 = null,
    name3 = "",
    val3 = null,
    name4 = "",
    val4 = null,
    name5 = "",
    val5 = null,
    name6 = "",
    val6 = null,
    name7 = "",
    val7 = null,
    name8 = "",
    val8 = null,
    name9 = "",
    val9 = null
  ) => {
    history({
      search: qs.stringify(
        name9
          ? {
              ...parameters,
              [name]: val,
              [name2]: val2,
              [name3]: val3,
              [name4]: val4,
              [name5]: val5,
              [name6]: val6,
              [name7]: val7,
              [name8]: val8,
              [name9]: val9,
            }
          : name8
          ? {
              ...parameters,
              [name]: val,
              [name2]: val2,
              [name3]: val3,
              [name4]: val4,
              [name5]: val5,
              [name6]: val6,
              [name7]: val7,
              [name8]: val8,
            }
          : name7
          ? {
              ...parameters,
              [name]: val,
              [name2]: val2,
              [name3]: val3,
              [name4]: val4,
              [name5]: val5,
              [name6]: val6,
              [name7]: val7,
            }
          : name6
          ? {
              ...parameters,
              [name]: val,
              [name2]: val2,
              [name3]: val3,
              [name4]: val4,
              [name5]: val5,
              [name6]: val6,
            }
          : name5
          ? {
              ...parameters,
              [name]: val,
              [name2]: val2,
              [name3]: val3,
              [name4]: val4,
              [name5]: val5,
            }
          : name4
          ? {
              ...parameters,
              [name]: val,
              [name2]: val2,
              [name3]: val3,
              [name4]: val4,
            }
          : name3
          ? { ...parameters, [name]: val, [name2]: val2, [name3]: val3 }
          : name2
          ? { ...parameters, [name]: val, [name2]: val2 }
          : { ...parameters, [name]: val }
      ),
    });
  };
};

export const useGetLocationData = () => {
  let location = useLocation();
  const currentParameters = qs.parse(location.search.slice(1));

  const checkEmpty = (name:any) => (name ? String(name) : "");

  const {
      table,
    searchField,
    searchText,
    modal,
      animation,
  } = currentParameters;

  return {
    ...currentParameters,

    searchField: checkEmpty(searchField),
      table: checkEmpty(table),
    modal: checkEmpty(modal),
      animation: checkEmpty(animation),

    searchText: checkEmpty(searchText),

  };
};

